import {
  ActionButton,
  Breadcrumbs,
  DeleteButton,
  DrawerButton,
  FormTextField,
  PageContent,
  Spinner,
  Table,
  useConfirm,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Stack, Typography } from '@mui/material'
import LogoutButton from 'components/SuperAdminLogoutButton'
import moment from 'moment'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import * as yup from 'yup'
import DocTypeForm from './DocTypeForm'
import {
  FrappeDataSyncLogs,
  StyledBanner,
  StyledStatusComponent,
} from './service'

const schema = yup.object().shape({
  baseUrl: yup
    .string()
    .url('Base URL must be a valid URL')
    .required('Base URL is required'),
  authToken: yup.string().required('Auth Token is required'),
  authSecret: yup.string().required('Auth Secret is required'),
})

export const FrappeeConfig = () => {
  const { id } = useParams()

  const queryClient = useQueryClient()
  const { data, isLoading } = useQuery(
    ['frappe-config', id],
    () => SERVICES.tenant.fetchClientById(id),
    {
      enabled: !!id,
    },
  )

  const { data: logsData, isLoading: logsLoading } = useQuery(
    ['frappe-logs', id],
    () => SERVICES.tenant.getFrappeDataSyncLogs(id),
    {
      enabled: !!id,
    },
  )

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      baseUrl: '',
      authToken: '',
      authSecret: '',
      enabled: '',
    },
  })

  useEffect(() => {
    if (data?.frappeConfig) {
      reset(data?.frappeConfig)
    }
  }, [data, reset])

  const { mutate, isLoading: updateLoading } = useMutation(
    (values) => {
      return SERVICES.tenant.createOrUpdateFrappeConfig({
        tenantId: id,
        body: values,
      })
    },
    {
      onSuccess: () => {
        toast.success(
          `Frappe Configuration ${
            data?.frappeConfig ? 'Updated' : 'Created'
          } Successfully`,
        )
        queryClient.invalidateQueries(['frappe-config', id])
      },
      onError: (error: any) => {
        toast.error(error.response.data.message)
      },
    },
  )

  const { mutate: toggleMutate, isLoading: toggleLoading } = useMutation(
    () => {
      return SERVICES.tenant.enableFrappeSync(id)
    },
    {
      onSuccess: () => {
        toast.success('Frappe Sync Enabled Successfully')
        queryClient.invalidateQueries(['frappe-config', id])
      },
      onError: (error: any) => {
        toast.error(error.response.data.message)
      },
    },
  )

  const { mutate: syncMutate, isLoading: syncLoading } = useMutation(
    () => SERVICES.tenant.frappeDataSync(id),
    {
      onSuccess: () => {
        toast.success('Frappe Configuration Sync has started')
        queryClient.invalidateQueries(['frappe-config', id])
      },
      onError: (error: any) => {
        toast.error(error.response.data.message)
      },
    },
  )

  const isInProgress = logsData?.logs?.some(
    (log) => log.status === 'IN_PROGRESS',
  )

  const onSubmit = (values) => {
    mutate(values)
  }

  if (isLoading) return <Spinner />

  return (
    <>
      <StyledBanner>
        <Typography variant="h1">Frappe Configuration</Typography>
        <LogoutButton />
      </StyledBanner>
      <PageContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumbs
            links={[
              { name: 'Clients', to: '/clients' },
              {
                name: !!id ? `${data?.name}` : 'New',
                to: `/config/clients/${id}`,
              },
              { name: 'Frappee Configuration', to: null },
            ]}
          />
        </Box>
        <Box maxWidth={'900px'} margin="20px auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} direction="column">
              <FormTextField
                label="Base URL"
                name="baseUrl"
                control={control}
                required
              />
              <FormTextField
                label="Auth Token"
                name="authToken"
                control={control}
                required
              />
              <FormTextField
                label="Auth Secret"
                name="authSecret"
                control={control}
                required
              />
              <Stack direction="row" spacing={2} justifyContent="center">
                <ActionButton type="submit" loading={updateLoading}>
                  {`${data?.frappeConfig ? 'Update' : 'Create'} Configuration`}
                </ActionButton>
                <ActionButton
                  onClick={() => toggleMutate()}
                  loading={toggleLoading}
                  disabled={!data?.frappeConfig || data?.frappeConfig?.enabled}
                >
                  Enable
                </ActionButton>
                <ActionButton
                  onClick={() => syncMutate()}
                  loading={syncLoading}
                  disabled={
                    !data?.frappeConfig?.enabled || syncLoading || isInProgress
                  }
                >
                  Sync Configuration
                </ActionButton>
              </Stack>
            </Stack>
          </form>
        </Box>
        <LogsTable data={logsData} isLoading={logsLoading} />
        <DocTypeTable
          data={data?.frappeConfig?.docTypes}
          isLoading={isLoading}
        />
      </PageContent>
    </>
  )
}

export const LogsTable = ({
  data,
  isLoading,
}: {
  data: FrappeDataSyncLogs
  isLoading: boolean
}) => {
  const columns = [
    {
      title: 'Stream Name',
      dataIndex: 'streamName',
      key: 'streamName',
      render: (_, row) => row?.streamName ?? '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, row) => {
        return <StyledStatusComponent status={row?.status} />
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, row) => moment(row?.createdAt).format('DD-MM-YYYY HH:mm'),
    },
  ]

  return (
    <Box maxWidth={'900px'} margin="20px auto">
      <Typography variant="h5" margin={'20px 0'}>
        Frappe Data Sync Logs
      </Typography>
      <Table
        columns={columns}
        dataSource={data?.logs ?? []}
        loading={isLoading}
      />
    </Box>
  )
}

export const DocTypeTable = ({ data, isLoading }: any) => {
  const { isConfirmed } = useConfirm()
  const { id } = useParams()
  const queryClient = useQueryClient()

  const { mutate } = useMutation(
    (values) => {
      return SERVICES.tenant.deleteDocType({
        tenantId: id,
        body: values,
      })
    },
    {
      onSuccess: () => {
        toast.success('Doc Type Deleted Successfully')
        queryClient.invalidateQueries('frappe-config')
      },
      onError: (error: any) => {
        toast.error(error.response.data.message)
      },
    },
  )

  const frappeDocTypeColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, row) => row?.type ?? '',
    },
    {
      title: 'Doc Type',
      dataIndex: 'value',
      key: 'value',
      render: (_, row) => row?.value ?? '',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, row) => (
        <Stack gap={1.5} direction={'row'}>
          <DeleteButton
            onClick={() => {
              handleDelete(row)
            }}
          />
        </Stack>
      ),
    },
  ]

  const handleDelete = async (data: any) => {
    const confirmed = await isConfirmed(
      'Are you sure you want to delete the Doc Type?',
    )

    if (!confirmed) return
    try {
      mutate(data)
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error deleting Doc Type')
    }
  }
  if (isLoading) return <Spinner />
  else
    return (
      <Box
        sx={{
          maxWidth: '900px',
          margin: '25px auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Frappe Doc Types</Typography>

          <DrawerButton
            title="Add Doc Type"
            anchor={({ open }) => <Button onClick={open}>Add Doc Type</Button>}
            content={({ close }) => <DocTypeForm close={close} />}
          />
        </Box>
        <Table
          columns={frappeDocTypeColumns}
          dataSource={data || []}
          loading={isLoading}
        />
      </Box>
    )
}
