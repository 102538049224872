import { ActionButton, FormSingleSelect, FormTextField } from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import * as yup from 'yup'

const schema = yup.object().shape({
  type: yup.string().required('Type is reaquired'),
  value: yup.string().required('Doc Type is reaquired'),
})

const DocTypeForm = ({ close }) => {
  const { id } = useParams()
  const queryClient = useQueryClient()

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: '',
      value: '',
    },
  })

  const { data: docTypes, isLoading: docTypeLoding } = useQuery(
    'get-doc-types',
    SERVICES.tenant.getDocTypes,
  )

  const { mutate } = useMutation(
    (values) => {
      return SERVICES.tenant.createDocType({
        tenantId: id,
        body: values,
      })
    },
    {
      onSuccess: () => {
        toast.success('Doc Type Added Successfully')
        queryClient.invalidateQueries('frappe-config')
        close()
      },
      onError: (error: any) => {
        toast.error(error.response.data.message)
        close()
      },
    },
  )

  const onSubmit = (formData) => {
    mutate(formData)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <FormSingleSelect
          key={`${docTypes}-type`}
          control={control}
          name="type"
          label="Type"
          options={docTypes?.data?.map((item) => {
            return {
              label: item,
              value: item,
            }
          })}
          required
          disabled={docTypeLoding}
        />
        <FormTextField
          name="value"
          label={'Doc Type'}
          control={control}
          required
        />
      </Stack>
      <Stack direction="row" gap={2} mt={6}>
        <ActionButton type="submit" fullWidth>
          Submit
        </ActionButton>
        <ActionButton variant="outlined" fullWidth onClick={close}>
          Cancel
        </ActionButton>
      </Stack>
    </form>
  )
}

export default DocTypeForm
